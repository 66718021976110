@media only screen and (min-width: 1220px) {
  .textback__form .phone-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px; } }

.textback__form .input-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background: #FFF;
  border: 1px solid transparent;
  border-radius: 100px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
  height: 60px;
  transition: border-color .3s ease;
  position: relative; }
  @media only screen and (min-width: 1220px) {
    .textback__form .input-wrapper {
      width: 350px; } }

.textback__form .focus .input-wrapper {
  border-color: #5335FF; }

.textback__form .error .input-mask {
  color: #FE2A55; }
  .textback__form .error .input-mask.placeholder {
    color: #FE2A55; }
  .textback__form .error .input-mask:-moz-placeholder {
    color: #FE2A55; }
  .textback__form .error .input-mask::-moz-placeholder {
    color: #FE2A55; }
  .textback__form .error .input-mask:-ms-input-placeholder {
    color: #FE2A55; }
  .textback__form .error .input-mask::-webkit-input-placeholder {
    color: #FE2A55; }
  .textback__form .error .input-mask::-webkit-text-fill-color {
    color: #FE2A55; }

.textback__form .input-mask {
  flex: 1;
  box-sizing: border-box;
  font-family: "Roobert", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #000;
  background-color: transparent; }
  @media only screen and (max-width: 1219px) {
    .textback__form .input-mask {
      padding: 0 0 0 60px; } }
  @media only screen and (min-width: 1220px) {
    .textback__form .input-mask {
      padding: 0 0 0 60px;
      width: 179px; } }
  .textback__form .input-mask.placeholder {
    color: #B3B3B3; }
  .textback__form .input-mask:-moz-placeholder {
    color: #B3B3B3; }
  .textback__form .input-mask::-moz-placeholder {
    color: #B3B3B3; }
  .textback__form .input-mask:-ms-input-placeholder {
    color: #B3B3B3; }
  .textback__form .input-mask::-webkit-input-placeholder {
    color: #B3B3B3; }
  .textback__form .input-mask::-webkit-text-fill-color {
    color: #B3B3B3; }

.textback__form .svg-icon {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 20px; }

@media only screen and (max-width: 1219px) {
  .textback__form .submit-button {
    width: 100%;
    margin-top: 16px; } }

.textback__form .error-line {
  background-color: #FE2A55;
  position: absolute;
  border-radius: 12px;
  padding: 12px;
  font-family: "Roobert", sans-serif;
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  bottom: 75px; }
  .textback__form .error-line:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    margin-left: -4px;
    border-top: 8px solid #FE2A55;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
