.footer {
  background: #fff;
  border-top: 1px solid #D6D6D6; }

.footer-holder {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 1219px) {
    .footer-holder {
      flex-direction: column;
      align-items: center;
      padding-top: 32px;
      padding-bottom: 32px; } }
  @media only screen and (min-width: 1220px) {
    .footer-holder {
      justify-content: space-between;
      align-items: flex-start;
      padding: 40px 106px 20px;
      gap: 46px; } }

/*
.footer-logo-icon {
  display: block;
  width: 95.911px;
  height: 24px;
}
*/
.footer-nav-list {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-auto-columns: max-content; }
  @media only screen and (max-width: 1219px) {
    .footer-nav-list {
      padding: 32px 40px 48px;
      gap: 12px;
      justify-content: space-between;
      width: 100%; } }
  @media only screen and (min-width: 1220px) {
    .footer-nav-list {
      /*
    grid-template-rows: repeat(2, 1fr);
    //columns: 2;
    */
      gap: 12px 100px; } }

/*
.footer-nav-item {
  @include respond-to(handhelds) {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
*/
.footer-nav-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666;
  /*
  transition: color .5s ease;
  &:hover {
    color: #F0C602;
  }
  */ }

@media only screen and (max-width: 1219px) {
  .footer-block {
    width: 100%; } }

@media only screen and (min-width: 1220px) {
  .footer-block {
    flex: 1; } }

.footer-inner-block {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .footer-inner-block {
      flex-direction: column;
      align-items: center; } }
  @media only screen and (min-width: 1220px) {
    .footer-inner-block {
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 20px; } }

.footer-payment-img {
  display: block;
  /*
  @include respond-to(handhelds) {
    width: 193.5px;
    height: 149px;
  }
  @include respond-to(wide-screens) {
    width: 379px;
    height: 102px;
  }
  */ }

.footer-contact-block {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #000; }
  @media only screen and (max-width: 1219px) {
    .footer-contact-block {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 52px;
      gap: 20px; } }
  @media only screen and (min-width: 1220px) {
    .footer-contact-block {
      justify-content: flex-start;
      padding-top: 20px;
      border-top: 1px solid var(--colors-additional-light, #F3F3F3); } }

.footer-contact, .footer-copyright {
  font-size: 12px;
  line-height: 16px;
  color: #000; }

.footer-copyright {
  opacity: .7; }
  @media only screen and (min-width: 1220px) {
    .footer-copyright {
      margin-left: auto; } }

.footer-contact-link {
  text-decoration: underline; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
