.join-modal .join-modal-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
  color: #666;
  text-align: center; }

.join-modal .form {
  display: flex;
  flex-direction: column;
  width: 100%; }

.join-modal .form-input {
  width: 100%;
  margin-top: 12px; }

.join-modal .link-button4 {
  width: 100%;
  margin-top: 12px; }

.join-modal .link-button5 {
  margin-top: 8px; }

.join-success-modal .join-success-img {
  margin-top: 20px; }

.join-success-modal .join-success-message {
  color: #666;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 24px; }

.join-success-modal .link-button4 {
  width: 100%;
  margin-top: 20px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
