.content-page {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000; }
  @media only screen and (min-width: 1220px) {
    .content-page {
      border-top: 1px solid #D6D6D6; } }
  @media only screen and (max-width: 1219px) {
    .content-page .content-page-holder {
      padding-top: 17px;
      padding-bottom: 80px; } }
  @media only screen and (min-width: 1220px) {
    .content-page .content-page-holder {
      padding: 52px 102px 80px; } }
  @media only screen and (min-width: 1220px) {
    .content-page .content-page-holder.report-abuse {
      padding-left: 207px;
      padding-right: 207px; } }
  .content-page .link-button3 {
    display: inline-flex;
    margin-top: 12px; }
    @media only screen and (max-width: 1219px) {
      .content-page .link-button3 {
        width: 100%; } }

.content-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 23px; }

.content-h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 8px; }

/*
.content-h3 {
  font-weight: bold;
  @include respond-to(handhelds) {
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
  }
  @include respond-to(wide-screens) {
    font-size: 20px;
    line-height: 28px;
    margin-top: 32px;
  }
}
*/
.content-p {
  margin-bottom: 20px; }

.content-p-subtitle {
  display: block;
  font-weight: bold;
  margin-bottom: 8px; }

.content-ul {
  margin-top: 8px;
  margin-bottom: 20px; }
  .content-ul > li {
    padding-left: 27px;
    font-family: "Roobert", sans-serif;
    position: relative; }
    .content-ul > li:before {
      content: "";
      background: #1B253D;
      display: block;
      position: absolute;
      top: 11px;
      left: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%; }
    .content-ul > li + li {
      margin-top: 6px; }

.content-inline-headline {
  display: inline; }

.content-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 20px 0;
  /*
  tr {
    &:last-child {
      td {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  th {
    text-align: left;
    //vertical-align: middle;
    background-color: #fff;
    font-weight: bold;
    padding: 8px 8px 12px 8px;
    &:first-child {
      padding-left: 8px;
      @include respond-to(handhelds) {
        width: 35%;
      }
      @include respond-to(wide-screens) {
        width: 26%;
      }
    }
    &:not(:first-child) {
      border-left: 1px solid #F6FAFF;
    }
  }
  th, td {
    border-collapse: collapse;
  }

  td {
    padding: 12px 0 8px 12px;
    border: 1px solid #fff;
    &:first-child {
      border-left: none;
      padding-left: 8px;
    }
    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
  */ }
  .content-table .column {
    border: 1px solid #ccc; }
  @media only screen and (max-width: 1219px) {
    .content-table .column {
      padding: 5px; }
    .content-table .column1 {
      width: 100px; }
    .content-table .column3 {
      width: 100px; } }
  @media only screen and (min-width: 1220px) {
    .content-table .column {
      padding: 10px; }
    .content-table .column1 {
      width: 200px; }
    .content-table .column3 {
      width: 180px; } }

/*
.content-link-wrapper {
  display: block;
  margin-top: 8px;
  &:first-child {
    margin-top: 16px;
  }
}
*/
.content-link {
  text-decoration-line: underline; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
