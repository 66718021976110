.join-block .join-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px; }
  @media only screen and (max-width: 1219px) {
    .join-block .join-holder {
      margin-bottom: 40px; } }
  @media only screen and (min-width: 1220px) {
    .join-block .join-holder {
      margin-bottom: 80px; } }

.join-block .join-message {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center; }

.join-block .join-link {
  color: #5335FF;
  text-decoration-line: underline; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
