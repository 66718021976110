.cookie-popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; }
  .cookie-popup .cookie-popup-holder {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 100; }
    @media only screen and (max-width: 1219px) {
      .cookie-popup .cookie-popup-holder {
        padding-bottom: 20px; } }
    @media only screen and (min-width: 1220px) {
      .cookie-popup .cookie-popup-holder {
        padding-bottom: 24px; } }
  .cookie-popup .cookie-banner {
    display: flex;
    padding: 12px 16px;
    border-radius: 12px;
    background: #FFF;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    pointer-events: auto; }
    @media only screen and (max-width: 1219px) {
      .cookie-popup .cookie-banner {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start; } }
    @media only screen and (min-width: 1220px) {
      .cookie-popup .cookie-banner {
        justify-content: flex-start;
        align-items: center;
        width: 700px; } }
  @media only screen and (max-width: 1219px) {
    .cookie-popup .cookie-img {
      width: 40px;
      height: 40px; } }
  .cookie-popup .cookie-message {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; }
    @media only screen and (max-width: 1219px) {
      .cookie-popup .cookie-message {
        width: calc(100% - 60px); } }
    @media only screen and (min-width: 1220px) {
      .cookie-popup .cookie-message {
        margin-left: 20px;
        width: 420px; } }
  .cookie-popup .cookie-link {
    color: #5335FF;
    text-decoration-line: underline; }
  @media only screen and (max-width: 1219px) {
    .cookie-popup .cookie-button {
      width: 100%;
      height: 44px;
      margin-top: 20px; } }
  @media only screen and (min-width: 1220px) {
    .cookie-popup .cookie-button {
      width: auto;
      margin-left: auto;
      padding: 24px 28px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
