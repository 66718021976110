@media only screen and (min-width: 1220px) {
  .about-hero .about-hero-holder {
    display: flex;
    gap: 27px; } }

.about-hero .about-hero-block {
  border-radius: 20px;
  background: #DAFF9E;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px; }
  @media only screen and (max-width: 1219px) {
    .about-hero .about-hero-block {
      padding: 20px;
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .about-hero .about-hero-block {
      padding: 28px 26px;
      height: 388px; } }

.about-hero .about-hero-title {
  display: block;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px; }

.about-hero .about-hero-message {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; }
  @media only screen and (max-width: 1219px) {
    .about-hero .about-hero-message {
      margin-top: 40px; } }

.about-hero .about-hero-subtitle {
  font-weight: 500;
  width: 100%; }
  @media only screen and (max-width: 1219px) {
    .about-hero .about-hero-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 60px; } }
  @media only screen and (min-width: 1220px) {
    .about-hero .about-hero-subtitle {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-top: auto; } }

@media only screen and (max-width: 1219px) {
  .about-block .about-block-holder {
    margin-top: 57px;
    margin-bottom: 48px; } }

@media only screen and (min-width: 1220px) {
  .about-block .about-block-holder {
    height: 272px;
    background: url("./img/bg.svg") no-repeat left top;
    margin-top: 56px;
    margin-bottom: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 140px;
    padding: 19px 0 0 106px; } }

.about-block .about-p1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; }
  @media only screen and (max-width: 1219px) {
    .about-block .about-p1 {
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .about-block .about-p1 {
      width: 492px; } }

.about-block .about-p2 {
  font-weight: 500; }
  @media only screen and (max-width: 1219px) {
    .about-block .about-p2 {
      text-align: center;
      margin-top: 40px;
      border-radius: 20px;
      background: linear-gradient(180deg, #F4FFE2 0%, #DAFF9E 100%);
      padding: 20px;
      font-size: 24px;
      line-height: 32px; } }
  @media only screen and (min-width: 1220px) {
    .about-block .about-p2 {
      width: 388px;
      font-size: 28px;
      line-height: 40px; } }

@media only screen and (min-width: 1220px) {
  .about-block .highlight {
    display: inline-block;
    color: #DAFF9E;
    border-radius: 100px;
    background: #000; }
    .about-block .highlight.highlight1 {
      padding: 2px 10px 3px; }
    .about-block .highlight.highlight2 {
      padding: 1px 16px 4px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
