.report-modal .join-success-img {
  margin-top: 20px; }

.report-modal .report-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
  color: #000;
  text-align: center; }

.report-modal .report-message {
  color: #666;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px; }

.report-modal .link-button4 {
  width: 100%;
  margin-top: 20px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
