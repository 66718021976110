.landing-hero .landing-hero-block {
  border-radius: 20px;
  background: var(--gradients-linear, linear-gradient(180deg, #F4FFE2 0%, #DAFF9E 100%));
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .landing-hero .landing-hero-block {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 23px 24px 36px; } }
  @media only screen and (min-width: 1220px) {
    .landing-hero .landing-hero-block {
      justify-content: space-between;
      align-items: center;
      padding: 24px 61px 27px 48px; } }

@media only screen and (min-width: 1220px) {
  .landing-hero .landing-hero-column {
    width: 530px; } }

.landing-hero .landing-title {
  font-weight: 700; }
  @media only screen and (max-width: 1219px) {
    .landing-hero .landing-title {
      font-size: 48px;
      line-height: 56px; } }
  @media only screen and (min-width: 1220px) {
    .landing-hero .landing-title {
      font-size: 60px;
      line-height: 60px; } }

.landing-hero .landing-second-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 20px; }
  @media only screen and (min-width: 1220px) {
    .landing-hero .landing-second-title {
      white-space: nowrap; } }
  .landing-hero .landing-second-title .highlight {
    font-weight: 700;
    color: #5335FF; }

.landing-hero .landing-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 40px; }

.landing-hero .landing-hero-img {
  display: block;
  width: 435px;
  height: 337px; }
  @media only screen and (max-width: 1219px) {
    .landing-hero .landing-hero-img {
      display: none; } }

.landing-hero .textback {
  margin-top: 20px; }
  .landing-hero .textback .agree {
    color: #666;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 16px; }

.result-section .result-wrapper {
  border-radius: 20px;
  background: #DAFF9E; }
  @media only screen and (max-width: 1219px) {
    .result-section .result-wrapper {
      padding: 20px 15px 32px; } }
  @media only screen and (min-width: 1220px) {
    .result-section .result-wrapper {
      padding: 44px 205px 75px; } }

.result-section .result-title {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px; }

.result-section .result-list {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .result-section .result-list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 24px; } }
  @media only screen and (min-width: 1220px) {
    .result-section .result-list {
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 32px; } }

.result-section .result-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.result-section .result-item-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #5335FF; }

.result-section .result-item-subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }
  @media only screen and (min-width: 1220px) {
    .result-section .result-item-subtitle {
      margin-top: 10px; } }

@media only screen and (max-width: 1219px) {
  .manager-section .manager-holder {
    margin-top: 60px;
    margin-bottom: 60px; } }

@media only screen and (min-width: 1220px) {
  .manager-section .manager-holder {
    background-image: image-set("./img/rounds.png" 1x, "./img/rounds@2x.png" 2x);
    background-position: bottom center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-start; } }

.manager-section .manager-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (min-width: 1220px) {
    .manager-section .manager-wrapper {
      width: 596px;
      padding: 62px 0 50px; } }

.manager-section .manager-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: #000;
  text-align: center; }

.manager-section .manager-message {
  color: #666;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 48px; }

@media only screen and (max-width: 1219px) {
  .services-section .services-holder {
    margin-top: 80px;
    margin-bottom: 60px; } }

@media only screen and (min-width: 1220px) {
  .services-section .services-holder {
    margin-top: 80px;
    margin-bottom: 43px; } }

.services-section .services-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; }
  @media only screen and (max-width: 1219px) {
    .services-section .services-title {
      flex-wrap: wrap; } }

.services-section .services-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #000;
  width: 52px;
  height: 52px;
  color: #DAFF9E;
  padding-bottom: 3px; }

.services-section .table-container {
  display: flex;
  flex-flow: column nowrap; }
  @media only screen and (max-width: 1219px) {
    .services-section .table-container {
      margin-top: 30px; } }
  @media only screen and (min-width: 1220px) {
    .services-section .table-container {
      width: 596px;
      margin: 47px auto 0; } }

.services-section .frame {
  border-radius: 8px;
  border: 4px solid #DAFF9E;
  padding: 12px 0; }

.services-section .table-row {
  display: flex;
  flex-flow: row nowrap; }

.services-section .heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 1219px) {
    .services-section .heading {
      padding-bottom: 20px; } }
  @media only screen and (min-width: 1220px) {
    .services-section .heading {
      padding-bottom: 25px; } }

.services-section .row-item {
  display: flex;
  align-items: center; }

.services-section .row-item1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  flex: 1;
  justify-content: flex-start; }
  @media only screen and (max-width: 1219px) {
    .services-section .row-item1 {
      padding: 6px 0 6px 20px; } }
  @media only screen and (min-width: 1220px) {
    .services-section .row-item1 {
      padding: 8px 0 8px 20px; } }

.services-section .row-item2 {
  padding-right: 30px;
  justify-content: center; }
  @media only screen and (max-width: 1219px) {
    .services-section .row-item2 {
      padding: 0 35px 0 20px; } }
  @media only screen and (min-width: 1220px) {
    .services-section .row-item2 {
      width: 140px; } }

.services-section .row-item3 {
  justify-content: center; }
  @media only screen and (max-width: 1219px) {
    .services-section .row-item3 {
      width: 54px; } }
  @media only screen and (min-width: 1220px) {
    .services-section .row-item3 {
      width: 90px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
