@media only screen and (max-width: 1219px) {
  .manager-block {
    margin-top: 48px;
    margin-bottom: 58px; } }

@media only screen and (min-width: 1220px) {
  .manager-block {
    margin-top: 80px;
    margin-bottom: 80px; } }

@media only screen and (min-width: 1220px) {
  .manager-block .manager-holder {
    display: flex;
    flex-direction: column;
    justify-content: center; } }

.manager-block .manager-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px; }
  @media only screen and (max-width: 1219px) {
    .manager-block .manager-title {
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .manager-block .manager-title {
      display: flex;
      margin: 0 auto;
      border-radius: 100px;
      background: #F3F3F3;
      padding: 8px 24px; } }

.manager-block .manager-list {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .manager-block .manager-list {
      flex-direction: column;
      gap: 24px;
      margin-top: 24px; } }
  @media only screen and (min-width: 1220px) {
    .manager-block .manager-list {
      justify-content: space-between;
      align-items: flex-start;
      padding: 44px 106px 0; } }

.manager-block .manager-item {
  display: flex;
  gap: 24px; }
  @media only screen and (max-width: 1219px) {
    .manager-block .manager-item {
      justify-content: flex-start;
      align-items: center; } }
  @media only screen and (min-width: 1220px) {
    .manager-block .manager-item {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 240px; } }

.manager-block .manager-pointer {
  align-self: center; }

.manager-block .manager-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }
  @media only screen and (min-width: 1220px) {
    .manager-block .manager-message {
      text-align: center; } }

.manager-block .manage-subtitle {
  font-weight: 500;
  text-align: center; }
  @media only screen and (max-width: 1219px) {
    .manager-block .manage-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px; } }
  @media only screen and (min-width: 1220px) {
    .manager-block .manage-subtitle {
      font-size: 28px;
      line-height: 40px; } }

@media only screen and (min-width: 1220px) {
  .manager-block.manager-block_thank-you .manager-title {
    font-size: 18px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
