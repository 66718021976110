@media only screen and (min-width: 1220px) {
  .hiw-hero .hiw-hero-holder {
    display: flex;
    gap: 27px; } }

.hiw-hero .hiw-hero-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px; }
  @media only screen and (min-width: 1220px) {
    .hiw-hero .hiw-hero-column {
      width: 388px; } }

.hiw-hero .hiw-hero-block {
  border-radius: 20px;
  background: #DAFF9E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px; }
  @media only screen and (max-width: 1219px) {
    .hiw-hero .hiw-hero-block {
      padding: 20px;
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .hiw-hero .hiw-hero-block {
      padding: 33px 26px; } }

.hiw-hero .hiw-hero-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px; }

.hiw-hero .hiw-hero-subtitle {
  font-weight: 500; }
  @media only screen and (max-width: 1219px) {
    .hiw-hero .hiw-hero-subtitle {
      font-size: 24px;
      line-height: 32px; } }
  @media only screen and (min-width: 1220px) {
    .hiw-hero .hiw-hero-subtitle {
      font-size: 16px;
      line-height: 24px; } }

@media only screen and (max-width: 1219px) {
  .hiw-steps .hiw-steps-holder {
    margin-top: 47px;
    margin-bottom: 32px; } }

@media only screen and (min-width: 1220px) {
  .hiw-steps .hiw-steps-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 60px; } }

.hiw-steps .hiw-steps-list {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .hiw-steps .hiw-steps-list {
      flex-direction: column;
      align-items: flex-start;
      gap: 28px; } }
  @media only screen and (min-width: 1220px) {
    .hiw-steps .hiw-steps-list {
      width: 1015px;
      justify-content: space-between;
      align-items: flex-start; } }

.hiw-steps .hiw-steps-item {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .hiw-steps .hiw-steps-item {
      align-items: flex-start;
      gap: 20px;
      align-self: stretch; } }
  @media only screen and (min-width: 1220px) {
    .hiw-steps .hiw-steps-item {
      flex-direction: column;
      align-items: flex-start;
      width: 247px; } }

.hiw-steps .hiw-item-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #DAFF9E;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  padding-bottom: 2px; }

.hiw-steps .hiw-item-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px; }
  @media only screen and (max-width: 1219px) {
    .hiw-steps .hiw-item-title {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 1220px) {
    .hiw-steps .hiw-item-title {
      margin: 11px 0 20px; } }

.hiw-steps .hiw-item-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #666; }

.hiw-steps .hiw-label {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 100px;
  background: #F3F3F3;
  font-weight: 500; }
  @media only screen and (max-width: 1219px) {
    .hiw-steps .hiw-label {
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px;
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .hiw-steps .hiw-label {
      font-size: 28px;
      line-height: 40px;
      margin: 60px auto 0; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
