@media only screen and (min-width: 1220px) {
  .become-hero .become-hero-holder {
    display: flex;
    gap: 27px; } }

.become-hero .become-hero-block {
  border-radius: 20px;
  background: #DAFF9E; }
  @media only screen and (max-width: 1219px) {
    .become-hero .become-hero-block {
      padding: 40px 20px;
      text-align: center; } }
  @media only screen and (min-width: 1220px) {
    .become-hero .become-hero-block {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 33px 26px 17px;
      height: 388px;
      width: 100%; } }

.become-hero .become-hero-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px; }

@media only screen and (max-width: 1219px) {
  .become-hero .become-hero-login-block {
    margin-top: 58px;
    /*      display: flex;
      flex-direction: column;
      //justify-content: flex-start;
      //align-items: flex-start;
      gap: 32px;*/ } }

@media only screen and (min-width: 1220px) {
  .become-hero .become-hero-login-block {
    margin-top: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 16px;
    align-items: center; } }

.become-hero .login-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }
  @media only screen and (max-width: 1219px) {
    .become-hero .login-title {
      margin-bottom: 8px; }
      .become-hero .login-title:not(:first-child) {
        margin-top: 32px; } }

@media only screen and (max-width: 1219px) {
  .become-hero .link-button4 {
    width: 100%; } }

.become-info-block .become-info-holder {
  text-align: center; }
  @media only screen and (max-width: 1219px) {
    .become-info-block .become-info-holder {
      margin-top: 47px; } }
  @media only screen and (min-width: 1220px) {
    .become-info-block .become-info-holder {
      margin: 60px auto 0;
      width: 800px; } }

.become-info-block .info-p1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; }

.become-info-block .info-p2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 24px; }

.become-info-block .link-button4 {
  margin-top: 20px; }
  @media only screen and (max-width: 1219px) {
    .become-info-block .link-button4 {
      width: 100%; } }
  @media only screen and (min-width: 1220px) {
    .become-info-block .link-button4 {
      margin-left: auto;
      margin-right: auto; } }

@media only screen and (max-width: 1219px) {
  .hiring-block .hiring-holder {
    margin-bottom: 40px; } }

@media only screen and (min-width: 1220px) {
  .hiring-block .hiring-holder {
    margin-bottom: 32px; } }

.hiring-block .hiring-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center; }

@media only screen and (max-width: 1219px) {
  .hiring-block .hiring-list {
    margin-top: 20px; } }

@media only screen and (min-width: 1220px) {
  .hiring-block .hiring-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /*
      grid-template-rows: repeat(2, 1fr);
      */
    grid-column-gap: 28px;
    margin-top: 44px; } }

.hiring-block .hiring-item {
  border-radius: 20px;
  background: linear-gradient(180deg, #F4FFE2 0%, #DAFF9E 100%); }
  @media only screen and (max-width: 1219px) {
    .hiring-block .hiring-item:not(:first-child) {
      margin-top: 28px; } }

.hiring-block .hiring-item-inner {
  height: 100%;
  padding: 20px; }
  .hiring-block .hiring-item-inner.item1 {
    background: url("./img/1.svg") no-repeat right 18px bottom; }
  .hiring-block .hiring-item-inner.item2 {
    background: url("./img/2.svg") no-repeat right 5px bottom; }
  .hiring-block .hiring-item-inner.item3 {
    background: url("./img/3.svg") no-repeat right 2px bottom; }
  .hiring-block .hiring-item-inner.item4 {
    background: url("./img/4.svg") no-repeat right bottom; }

.hiring-block .hiring-item-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px; }

.hiring-block .hiring-item-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
