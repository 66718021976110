@media only screen and (max-width: 1219px) {
  .thank-you-hero .thank-you-hero-holder {
    padding-top: 17px; } }

@media only screen and (min-width: 1220px) {
  .thank-you-hero .thank-you-hero-holder {
    padding-top: 32px; } }

.thank-you-hero .thank-you-hero-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #DAFF9E; }
  @media only screen and (max-width: 1219px) {
    .thank-you-hero .thank-you-hero-block {
      padding: 32px 20px; } }
  @media only screen and (min-width: 1220px) {
    .thank-you-hero .thank-you-hero-block {
      padding: 40px; } }

.thank-you-hero .thank-you-title {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  text-align: center; }

.thank-you-hero .thank-you-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
  color: #666; }

@media only screen and (max-width: 1219px) {
  .thank-you-hero .textback {
    margin-top: 20px; } }

@media only screen and (min-width: 1220px) {
  .thank-you-hero .textback {
    margin-top: 44px; } }

@media only screen and (max-width: 1219px) {
  .thank-you-email-block .thank-you-email-holder {
    margin-top: 40px; } }

@media only screen and (min-width: 1220px) {
  .thank-you-email-block .thank-you-email-holder {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.thank-you-email-block .thank-you-email-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; }

.thank-you-email-block .email-form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  margin-top: 18px; }
  @media only screen and (max-width: 1219px) {
    .thank-you-email-block .email-form {
      flex-direction: column; } }

@media only screen and (max-width: 1219px) {
  .thank-you-email-block .input-wrapper {
    width: 100%; } }

@media only screen and (min-width: 1220px) {
  .thank-you-email-block .input-wrapper {
    width: 284px; } }

.thank-you-email-block .form-item-error {
  margin: 4px 0 0; }

.thank-you-email-block .form-input {
  width: 100%; }

@media only screen and (max-width: 1219px) {
  .thank-you-email-block .submit-button {
    width: 100%; } }

.thank-you-email-block .thank-you-sharing-title {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px; }

.thank-you-email-block .thank-you-sharing-subtitle {
  color: #666;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 12px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
