@media only screen and (max-width: 1219px) {
  .feedback-list {
    margin-top: 32px; } }

@media only screen and (min-width: 1220px) {
  .feedback-list {
    margin-top: 44px; } }

.feedback-list .feedback-item {
  border-radius: 8px;
  border: 1px solid #D6D6D6;
  padding-bottom: 12px;
  width: 318px;
  margin: 0 auto 1px; }

.feedback-list .feedback-item-inner {
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto; }

.feedback-list .feedback-inner {
  padding: 15px 16px 16px; }
  @media only screen and (max-width: 1219px) {
    .feedback-list .feedback-inner {
      height: 228px; } }

.feedback-list .cell-header-img {
  margin: 0 auto; }

.feedback-list .slider {
  /*
    .slick-slide {
      @include respond-to(handhelds) {
        &:first-child {
          padding-left: 20px;
        }
        &:not(:first-child) {
          padding-left: 8px;
        }
      }
    }
    */
  /* Arrows */ }
  @media only screen and (min-width: 1220px) {
    .feedback-list .slider {
      margin: 0 88px; } }
  .feedback-list .slider .slick-dots {
    display: flex !important;
    gap: 8px; }
    @media only screen and (max-width: 1219px) {
      .feedback-list .slider .slick-dots {
        margin: 20px auto 0; } }
    @media only screen and (min-width: 1220px) {
      .feedback-list .slider .slick-dots {
        margin: 36px auto 0; } }
    .feedback-list .slider .slick-dots li {
      display: block; }
  .feedback-list .slider .slick-dots-btn {
    display: block; }
    .feedback-list .slider .slick-dots-btn:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #D6D6D6; }
  .feedback-list .slider .slick-active .slick-dots-btn:after {
    background: #5335FF; }
  .feedback-list .slider .main-slider {
    display: flex;
    flex-direction: column; }
    .feedback-list .slider .main-slider .slick-list > .slick-track > .slick-slide:not(.slick-active) {
      pointer-events: none; }
  .feedback-list .slider .slick-prev, .feedback-list .slider .slick-next {
    display: block;
    font-size: 0;
    line-height: 0;
    position: absolute;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent; }
    @media only screen and (min-width: 1220px) {
      .feedback-list .slider .slick-prev, .feedback-list .slider .slick-next {
        top: 191px; } }
    .feedback-list .slider .slick-prev:before, .feedback-list .slider .slick-next:before {
      content: '';
      display: block; }
      @media only screen and (min-width: 1220px) {
        .feedback-list .slider .slick-prev:before, .feedback-list .slider .slick-next:before {
          width: 41px;
          height: 41px; } }
  @media only screen and (min-width: 1220px) {
    .feedback-list .slider .slick-prev {
      left: -73px; }
      .feedback-list .slider .slick-prev:before {
        background: url("./img/arrow-left.svg") no-repeat center; } }
  @media only screen and (min-width: 1220px) {
    .feedback-list .slider .slick-next {
      right: -73px; }
      .feedback-list .slider .slick-next:before {
        background: url("./img/arrow-right.svg") no-repeat center; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
