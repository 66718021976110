@media only screen and (min-width: 1220px) {
  .tutors-hero .tutors-hero-holder {
    display: flex;
    gap: 27px; } }

.tutors-hero .tutors-hero-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px; }
  @media only screen and (min-width: 1220px) {
    .tutors-hero .tutors-hero-column {
      width: 388px; } }

.tutors-hero .tutors-hero-block {
  border-radius: 20px;
  background: #DAFF9E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; }
  @media only screen and (max-width: 1219px) {
    .tutors-hero .tutors-hero-block {
      padding: 20px;
      text-align: center;
      gap: 16px; } }
  @media only screen and (min-width: 1220px) {
    .tutors-hero .tutors-hero-block {
      padding: 20px 28px 28px;
      flex: 1; } }

.tutors-hero .tutors-hero-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px; }

.tutors-hero .tutors-hero-subtitle {
  font-weight: 500; }
  @media only screen and (max-width: 1219px) {
    .tutors-hero .tutors-hero-subtitle {
      font-size: 24px;
      line-height: 32px; } }
  @media only screen and (min-width: 1220px) {
    .tutors-hero .tutors-hero-subtitle {
      font-size: 18px;
      line-height: 28px; } }

@media only screen and (max-width: 1219px) {
  .tutors .tutors-holder {
    margin-top: 23px;
    margin-bottom: 44px; } }

@media only screen and (min-width: 1220px) {
  .tutors .tutors-holder {
    margin-top: 40px;
    margin-bottom: 44px; } }

@media only screen and (min-width: 1220px) {
  .tutors .tutors-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 28px; } }

.tutors .tutor-item {
  border-radius: 8px;
  border: 1px solid #D6D6D6;
  background-color: #FFF;
  padding: 20px;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 1219px) {
    .tutors .tutor-item:not(:first-child) {
      margin-top: 20px; } }

.tutors .tutor-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D6D6D6; }

.tutors .tutor-img {
  display: block;
  border-radius: 50%; }

.tutors .tutor-name-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px; }

.tutors .tutor-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }

.tutors .tutor-button {
  margin-left: auto; }

.tutors .tutor-middle {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: flex-start; }

.tutors .tutor-middle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; }

.tutors .tutor-middle-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; }

.tutors .tutor-middle-value {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px; }

.tutors .tutor-middle-label {
  display: flex;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #DAFF9E; }

.tutors .tutor-greeting {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666;
  padding: 4px 0 16px;
  flex: 1; }

.tutors .tutor-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 8px;
  border-top: 1px solid #D6D6D6; }

.tutors .tutor-bottom-title {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap; }

.tutors .tutor-helps {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: right; }

.tutors .show-more-button {
  display: flex;
  height: 44px;
  padding: 0 28px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #F3F3F3;
  color: #5335FF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 24px auto; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
