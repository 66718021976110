@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(3); } }

.Ripple-parent.pushState {
  transform: translateY(1px); }

.Ripple {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 1;
  transform: scale(0); }
  .Ripple.is-reppling {
    animation: ripple 0.5s linear; }
  .Ripple-parent {
    position: relative;
    overflow: hidden; }
