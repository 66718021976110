.header {
  background-color: #fff; }

.header-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px; }

.header-logo-icon {
  display: block;
  width: 120px;
  height: 30px; }

.header-nav-mob {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999; }

.header-nav-holder {
  position: relative; }

.header-nav-open-icon {
  display: block;
  width: 24px;
  height: 24px; }

.header-nav-close-btn {
  display: block;
  padding: 5px; }

.header-nav-close-icon {
  display: block;
  width: 14px;
  height: 14px; }

.header-nav-list {
  display: flex; }
  @media only screen and (max-width: 1219px) {
    .header-nav-list {
      flex-direction: column; } }

@media only screen and (min-width: 1220px) {
  .header-nav-item:not(:first-child) {
    margin-left: 40px; } }

.header-nav-link {
  color: #000;
  font-weight: 500;
  /*
  transition: color .5s ease;
  &:hover {
    color: #F0C602;
  }
  */ }
  @media only screen and (max-width: 1219px) {
    .header-nav-link {
      display: inline-block;
      font-size: 18px;
      line-height: 28px;
      padding: 24px 20px; } }
  @media only screen and (min-width: 1220px) {
    .header-nav-link {
      font-size: 14px;
      line-height: 20px; } }
  .header-nav-link.active {
    color: #5335FF; }

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
