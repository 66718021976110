.health-check-popup-layout {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: 'Axiforma', 'Open Sans', sans-serif; }

.health-check-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff; }
  @media only screen and (max-width: 1019px) {
    .health-check-main {
      width: 335px;
      align-items: center;
      padding: 20px 13px 32px;
      text-align: center; } }
  @media only screen and (min-width: 1020px) {
    .health-check-main {
      width: 627px;
      align-items: flex-start;
      position: relative;
      padding: 44px 25px 44px 293px; } }

.health-check-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #023135; }

.health-check-subtitle {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.17px;
  color: #023135;
  margin-top: 12px; }

.health-check-img {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .health-check-img {
      width: 200px;
      height: 136px;
      margin-top: 24px; } }
  @media only screen and (min-width: 1020px) {
    .health-check-img {
      position: absolute;
      top: 44px;
      left: 30px;
      width: 226px;
      height: 154px; } }

.health-check-line {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #007a5c;
  margin-bottom: 32px; }
  @media only screen and (max-width: 1019px) {
    .health-check-line {
      margin-top: 24px; } }
  @media only screen and (min-width: 1020px) {
    .health-check-line {
      margin-top: 20px; } }

.health-check-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  border-radius: 4px;
  background-color: #007a5c;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #fff; }
