.login-modal .form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px; }

.login-modal .form-input {
  width: 100%;
  margin-top: 12px; }

.login-modal .link-button4 {
  width: 100%;
  margin-top: 12px; }

.login-modal .link-button5 {
  margin-top: 8px; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
