.bubble {
  border-radius: 14px;
  padding: 10px;
  font-size: 15px;
  line-height: 20px;
  max-width: 225px;
  position: relative; }
  .bubble::before, .bubble::after {
    content: "";
    bottom: -0.1rem;
    height: 1rem;
    position: absolute; }
  .bubble.outgoing {
    background: #5335FF;
    margin-left: auto;
    color: #fff; }
    .bubble.outgoing::before {
      border-bottom-left-radius: 0.8rem 0.7rem;
      border-right: 1rem solid #5335FF;
      right: -0.35rem;
      transform: translate(0, -0.1rem); }
    .bubble.outgoing::after {
      background-color: #fff;
      border-bottom-left-radius: 0.5rem;
      right: -40px;
      transform: translate(-30px, -2px);
      width: 10px; }
  .bubble.incoming {
    background: #F3F3F3;
    margin-right: auto;
    color: #000; }
    .bubble.incoming::before {
      border-bottom-right-radius: 0.8rem 0.7rem;
      border-left: 1rem solid #F3F3F3;
      left: -0.35rem;
      transform: translate(0, -0.1rem); }
    .bubble.incoming::after {
      background-color: #fff;
      border-bottom-right-radius: 0.5rem;
      left: 20px;
      transform: translate(-30px, -2px);
      width: 10px; }
